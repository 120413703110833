<template>
  <router-view/>
</template>

<style>

</style>
<script>

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  
  data() {
    return {
      connection: null
    }
  },
  methods: {
    
    send() {
      console.log("Send message:" + this.send_message);
      if (this.stompClient && this.stompClient.connected) {
        const msg = { name: this.send_message };
        console.log(JSON.stringify(msg));
        this.stompClient.send("/app/hello", JSON.stringify(msg), {});
      }
    },
    
    connect() {
      
      this.socket = new SockJS("http://localhost:8080/transview");

      this.stompClient = Stomp.over(this.socket);
      this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
      this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
      
      this.stompClient.connect({},
        frame => {
          this.connected = true;
          console.log(frame);
          this.stompClient.subscribe('/topic/300591', function (result) {
            console.log(result);
            // this.received_messages.push(JSON.parse(tick.body).content);

          });
        },
        error => {
          console.log(error);
          this.connected = false;
        }
      );


    },
    
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
    
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    }

  },
  created() {
    // this.connect();

  }
  
}
</script>
