<template>
  <div class="login-page" >
      <div class="login-box">
        <div class="logo text-center">
            <a href="javascript:void(0);"><img src="@/assets/GhIPSS-logo.png" style="width:200px" /></a>
            <h2 class="col-ghipss">TransView</h2>
          </div>
          <div class="card">
              <div class="body">
                  <form @submit.prevent="performPasswordReset">
                      <div class="msg">
                          Enter your email address (username) and we'll send you an email with a new password.
                      </div>
                        <div class="input-group">
                          <span class="input-group-addon">
                              <i class="material-icons">email</i>
                          </span>
                          <div class="form-line">
                              <input type="text" class="form-control" v-model="username" placeholder="Email address" autofocus
                              :class="{'is-invalid': v$.username.$error, 'is-valid': !v$.username.$invalid }">
                          </div>
                          <span v-if="v$.username.$error" class="text-danger">
                            {{ v$.username.$errors[0].$message }}
                          </span>                            
                      </div>

                      <button class="btn btn-block btn-lg btn-ghipss waves-effect">RESET MY PASSWORD</button>

                      <div class="row m-t-20 m-b--5 align-center">
                          <router-link to="/"><span class="col-ghipss">Back to login!</span></router-link>

                      </div>
                      <img src="@/assets/background-img.jpg" class="login-illus" alt="">

                  </form>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
  .login-page{
    background: none;
  }  
</style>


<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },    
  name: 'ForgotPassword',
  components: {

  },
  data() {
    return {
      username: '',
      generalResponse: {}
    }
  },
  validations() {
    return{
      username: {
        required,
        email
      }
    }
  },    
  methods: {
      async performPasswordReset() {
        this.v$.$validate() 
        if (!this.v$.$error) { 
          $.LoadingOverlay("show");
          try {
              const response = await axios.post('user/forgot_password/'+this.username);
              if(response.status == 200){
                this.generalResponse = response.data;
                if(this.generalResponse.errorCode == '0'){
                  this.$swal('Success', 'Password reset successful!', 'success')                
                    this.$router.push('/')
                }else{
                  this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                }
              }else{
                this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

              }
          }
          catch(err) {
              this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

          }finally{
            $.LoadingOverlay("hide");
            this.username = '';
            this.v$.$reset();

          }
          
        }
    },

  },
}
</script>