<template>
    <div class="theme-ghipss">
        <div class="overlay"></div>
            <top-nav />
        <section>
            <left-nav />
            <right-nav />
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="block-header">
                    <h2>ARCHIVED TCS INWARD TRANSACTIONS</h2>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="body">
                                <div class="row clearfix">

                                    <div class="col-lg-4 col-md-4 col-xs-12">
                                        <h2 class="card-inside-title">Transaction Date</h2>
                                        <div class="input-daterange input-group" id="bs_datepicker_range_container" data-date-format="yyyy-mm-dd">
                                            <div class="form-line">
                                                <input type="text" class="form-control" id="sdate" placeholder="Start Date..."  v-model="startDate" >                                               
                                            </div>
                                                <span v-if="v$.startDate.$error" class="text-danger">
                                                    {{ v$.startDate.$errors[0].$message }}
                                                </span>                                             
                                 
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-2 col-xs-12 m-t-30">
                                        <span v-if="!isDownloading">
                                            <button @click.prevent="loadData" class="btn btn-ghipss waves-effect">Generate</button>
                                        </span>
                                        <span v-else><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> busy</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>                

                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="txn_table_div">
                        <div class="card">
                            <div class="row header">
                                <div class="pull-left">
                                    <button type="button" class="btn bg-green" data-toggle="modal"
                                        data-target="#defaultModal" >NOTES</button>
                                </div>
                                <!-- <div class="pull-right" style="margin:-10px 0px 0px 0px" v-show="firstSearch">
                                    <button type="button" class="btn btn-default btn-circle waves-effect waves-circle waves-float" @click="getLiveOutwardTransactionsByBank">
                                        <i class="fa fa-sync-alt"></i>
                                    </button>                  
                                    <i class="far fa-clock" style="margin-left:10px"></i> {{ lastUpdateTime }}
                                </div> -->
                            </div>
                            <div class="row body">
                                <div class="container-fluid">
                                    <div class="pull-left">
                                        <div class="btn-group" role="group" v-show="firstSearch" v-if="!isDownloading">
                                            <button type="button" class="btn btn-default waves-effect" @click.prevent="exportExcel">EXCEL</button>
                                            <button type="button" class="btn btn-default waves-effect" @click.prevent="exportCSV">CSV</button>
                                            <!-- <button type="button" class="btn btn-default waves-effect" @click.prevent="exportPDF">PDF</button> -->
                                        </div>
                                        <div v-else>
                                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> busy
                                        </div>
                                    </div>
                                    <div class="pull-right">
                                        <div class="form-group" style="margin:-10px 0px 0px 0px">
                                            <div class="form-line">
                                                <input type="text" placeholder="Search here..." v-model="searchInput" class="form-control" @input="searchEvent" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Sender Inst.</th>
                                                <th>Sender Acct. Number</th>
                                                <th>Receiver Inst.</th>
                                                <th>Receiver Acct. Number</th>
                                                <th style="text-align:left">Amount</th>
                                                <th>Original Action Code</th>
                                                <th>Final Action Code</th>
                                                <th>Date/Time</th>
                                                <th>Check Status</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="filteredEntries.length > 0">
                                            <tr v-for="(transaction, i) in filteredEntries" :key="i">
                                                <td>{{ transaction.reference }}</td>
                                                <td>{{ transaction.acquirer }}</td>
                                                <td>{{ transaction.source_account }}</td>
                                                <td>{{ transaction.issuer }}</td>
                                                <td>{{ transaction.destination_account }}</td>
                                                <td class="text-right">{{ transaction.amount }}</td>
                                                <td>{{ transaction.original_status }}</td>
                                                <td>{{ transaction.final_status }}</td>
                                                <td>{{ transaction.transmission_date_and_time }}</td>
                                                <td>
                                                    <span v-if="transaction.checked_status == '0'" class="label bg-red">Unchecked</span>
                                                    <span v-else-if="transaction.checked_status == '1'" class="label bg-amber">Checking</span>
                                                    <span v-else-if="transaction.checked_status == '2'" class="label bg-green">Checked</span>
                                                    <span v-else-if="transaction.checked_status == '3'" class="label bg-black">Not Available</span>

                                                </td>                                                
                                                <!-- <td>{{ transaction.checked_status }}</td> -->

                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="10" class="text-center">No records</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 10px 20px">
                                            <span class="pagination">
                                                showing {{ showInfo.from }} to {{ showInfo.to }} of {{ showInfo.of }} entries
                                            </span>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <ul class="pagination pull-right">
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>                      
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="currentPage < 1 ? currentPage = 1 : currentPage -= 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li v-for="pagi in showPagination" :key="pagi" :class="['page-item', {'disabled': pagi === '...', 'active': pagi === currentPage}]">
                                                    <a href="#" class="page-link" @click.prevent="paginateEvent(pagi)">{{ pagi }}</a>
                                                </li>                    
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a class="page-link" href="#" aria-label="Next" @click.prevent="currentPage > allPages ? currentPage = allPages : currentPage += 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = allPages, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>            
                                                    </a>
                                                </li>                    
                                            </ul>            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>

        <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title">
                            NOTES
                        </h2>                        
                    </div>
                    <div class="modal-body">
                        <b>1. Check Status field contains any of the following values;</b>
                        <li><span class="label bg-red">unchecked</span> = the transaction has not been checked for its final status (yet). this could be because the receiving institution does not support TCS checks or the status is yet to be checked.</li>
                        <li><span class="label bg-amber">checking</span> = the status of the transaction is being checked. </li>
                        <li><span class="label bg-green">checked</span> = the transaction has been checked for its final status. in this case, there should be a value in the "Final Action Code" field.</li>
                        <li><span class="label bg-black">not available</span> = the transaction was sent to an institution that has not implemented TCS. in this case, the transaction is treated as failed with "Final Action Code" = 100.</li>

                        <br/>
                        <b>2. Final Action Code field contains any of the following values;</b>
                        <li>000 = transaction is successful</li>
                        <li>999 = transaction not found in receiver system (failed)</li>
                        <li>100 = transaction failed</li>
                        <li>990 = transaction is being processed/checked (check again)</li>
                        <br/>
                        <b>3. This section contains transactions that has the original action codes as any of the following;</b>
                        <li>909 = system fault</li>
                        <li>912 = timeout</li>
                        <li>null = no response</li>
                        <br/>
                        <b>4. Any exception to the above scenarios should be communicated to GhIPSS for further checks.</b>                         

                    </div>
                </div>
            </div>
        </div>        

    </div>
</template>
<style scoped>

</style>
<script>
// import '../../../public/js/admin.js'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import axios from 'axios';
import TopNav from "@/components/TopNav.vue";
import LeftNav from "@/components/LeftNav.vue";
import RightNav from "@/components/RightNav.vue";

import VueSelect from 'vue-next-select'
import '../../../public/css/vue-next-select-refactored.css'


import { $array } from 'alga-js';
import moment from "moment";

export default {
    name: 'ArchiveTcsInwardTxns',
    components:{
        TopNav,
        LeftNav,
        RightNav,
        VueSelect
    },

    data(){
        return {
            v$: useVuelidate(),

            allBanks: [],
            bankId: '',
            fiName: '',
            secBankId: '',
            secBankName: '',
            transactions: [],
            isDownloading: false,
            firstSearch: false,
            lastUpdateTime: '',
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            // endDate: moment(new Date()).format('YYYY-MM-DD'),

            entries:[],
            showEntries:[5, 10, 15, 25, 50, 75, 100],
            currentEntries: 10,
            filteredEntries: [],
            currentPage: 1,
            allPages: 1,
            searchInput: '',
            searchEntries: [], 
        }
    },
    validations() {
      return{
        startDate: {
          required,
        },
        // endDate: {
        //   required,
        // },        
      }
    },     
    methods: {
        getOnlyDate(date) {
            return moment(date).format("YYYY-MM-DD");
        }, 

        handleSearchInput(event){
            // console.log(event.fiCode)
            this.fiName = event.bankName
        },


        async exportExcel(){
            this.isDownloading = true;
            // const data = {
            //     transactionList: this.transactions,
            //     }
                // console.log(data);
            axios({
                url: `tcs/generate_tcs_archived_txns_report/EXCEL`,
                method: 'POST',
                data: this.transactions,
                responseType: 'blob',
            }).then((response) => {
            if (response.status == 200) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.bankId+'_tcs_'+this.startDate+'.xlsx');
                document.body.appendChild(fileLink);

                fileLink.click();
            }else{
                this.$swal('Failed', 'Error downloading report!', 'error')                
            }
            }).catch((err)=>{
                console.log(err)
                this.$swal('Failed', 'Error downloading report!', 'error')                

            }).finally(()=>{
                this.isDownloading = false;
                // this.clearValidation();

            });
        }, 

        async exportCSV(){
            this.isDownloading = true;
            // const data = {
            //     transactionList: this.allTransactions,
            //     }
                // console.log(data);
            axios({
                url: `tcs/generate_tcs_archived_txns_report/CSV`,
                method: 'POST',
                data: this.transactions,
                responseType: 'blob',
            }).then((response) => {
            if (response.status == 200) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.bankId+'_tcs_'+this.startDate+'.csv');
                document.body.appendChild(fileLink);

                fileLink.click();
            }else{
                this.$swal('Failed', 'Error downloading report!', 'error')                
            }
            }).catch((err)=>{
                console.log(err)
                this.$swal('Failed', 'Error downloading report!', 'error')                

            }).finally(()=>{
                this.isDownloading = false;
                // this.clearValidation();

            });
        },  


        async loadData() {
            this.startDate = $("#sdate").val();
            // this.endDate = $("#edate").val();

            this.v$.$validate();      
            // this.v$.bankId.$touch;
            this.v$.startDate.$touch;
            // this.v$.endDate.$touch;
            if (!this.v$.startDate.$invalid) {             

                try{
                    $("#txn_table_div").LoadingOverlay("show");
                    this.isDownloading = true;

                const response = await axios.get(`tcs/get_archived_in_txns_by_bank/${this.bankId}/${this.startDate}`);
                if (response.status == 200) {
                    this.transactions = response.data;
                    this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                    this.allPages = $array.pages(this.transactions, this.currentEntries)   

                    this.totalAmount = response.data.map(e => parseFloat(e.transaction_amount)).reduce((a,b) => a + b, 0);
                    this.lastUpdateTime = moment(new Date()).format('hh:mm a');

                    this.firstSearch = true;
                    this.lastUpdateTime = moment(new Date()).format('hh:mm a');

                    this.secBankId = this.bankId;
                    this.secBankName = this.fiName;
                }else{
                    this.$swal('Failed', 'Error loading records!', 'error')                

                }
                }catch(err){
                    this.$swal('Failed', 'Error loading records!', 'error')                

                }finally{
                    $("#txn_table_div").LoadingOverlay("hide");
                    this.isDownloading = false;
                }

            }
        },

        // getSettledOutwardTransactionsByBank() {
        //     this.startDate = $("#sdate").val();
        //     this.endDate = $("#edate").val();

        //     this.v$.$validate();      
        //     this.v$.bankId.$touch;
        //     this.v$.startDate.$touch;
        //     this.v$.endDate.$touch;

        //     if (!this.v$.bankId.$invalid && !this.v$.startDate.$invalid && !this.v$.endDate.$invalid) {             
        //         // this.startDate = $("#sdate").val();
        //         // this.endDate = $("#edate").val();

        //         this.$swal({
        //         //   title: 'Delete ( '+ this.bankUser.fullName+ ')',
        //         //   text: 'A wide Business Date Range Will Take A Long Time To Generate Report.',
        //         html:
        //             '<center><p>A wide business date range will take a long time to generate report.</p>' +
        //             '<p>Use a shorter range for faster results.</p></center>',
        //         // type: 'info',
        //         icon: 'info',
        //         showCancelButton: true,
        //         confirmButtonText: 'Proceed!',
        //         cancelButtonText: 'Change!',
        //         showCloseButton: true,
        //         showLoaderOnConfirm: true
        //         }).then((result) => {
        //             if(result.value) {
        //                 this.loadData(this.bankId, this.startDate, this.endDate)

        //             } 
        //         })
        //     }
        // },

        paginateEntries(){
            if(this.searchInput.length >= 1){
                this.searchEntries = $array.search(this.searchInput)(this.transactions)
                this.filteredEntries = $array.paginate(this.searchEntries)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.searchEntries, this.currentEntries)

            }else{
                this.searchEntries = []
                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.transactions, this.currentEntries)
            
            }
        },

        paginateEvent(page){
            this.currentPage = page;
            this.paginateEntries();
        },
    
        searchEvent(){
            this.currentPage = 1
            this.paginateEntries()
        },         
    },
    
    mounted() {

        if(localStorage.getItem('bankcode') != '000001'){
            if(localStorage.getItem('rolecode') != '2' && localStorage.getItem('rolecode') != '3' && localStorage.getItem('rolecode') != '5' 
                && localStorage.getItem('rolecode') != '100'){
                    this.$router.push('/')
            }else{
                    let externalScript = document.createElement('script')
                    externalScript.setAttribute('src', '/js/admin.js')
                    document.body.appendChild(externalScript)

                    $('#bs_datepicker_range_container').datepicker({
                        autoclose: true,
                        startDate: "-3m",
                        endDate: "+0d",
                        clearBtn: true,
                    //  daysOfWeekDisabled: "0,6",
                        calendarWeeks: true,
                        todayHighlight: true
                    });

                    this.moment = moment;

                    this.bankId = localStorage.getItem('bankcode');

                    // this.getAllBanks();                 
                }
        }else{
            this.$router.push('/')

        }          
    },

    computed: {
        // showSelectedDetails(){
        //     return this.secBankId + ' - ' + this.secBankName+' ( '+this.startDate+' - '+this.endDate+' )'
        // },

        showInfo() {
            const getCurrentEntries = this.searchEntries.length <= 0 ? this.transactions : this.searchEntries
            return $array.show(getCurrentEntries)(this.currentPage, this.currentEntries)
        },
        
        showPagination(){
            return $array.pagination(this.allPages, this.currentPage, 1)
        }
    },
}
</script>