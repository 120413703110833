import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'

import SuperLiveInwardTxns from '../views/ghipss/LiveInwardTxns.vue'
import SuperLiveOutwardTxns from '../views/ghipss/LiveOutwardTxns.vue'
import SuperSettledOutwardTxns from '../views/ghipss/SettledOutwardTxns.vue'
import SuperSettledInwardTxns from '../views/ghipss/SettledInwardTxns.vue'
import Institutions from '../views/ghipss/Institutions.vue'
import SuperUsers from '../views/ghipss/Users.vue'
import SuperNetSettlement from '../views/ghipss/NetSettlement'
import SuperSettledEzInwardTxns from '../views/ghipss/SettledEzInwardTxns'
import SuperSettledEzOutwardTxns from '../views/ghipss/SettledEzOutwardTxns'

import SuperRealTimeTxns from '../views/ghipss/RealTimeTxns'
import SuperRealTimeErrors from '../views/ghipss/RealTimeErrors'

import BankLiveInwardTxns from '../views/bank/LiveInwardTxns.vue'
import BankLiveOutwardTxns from '../views/bank/LiveOutwardTxns.vue'
import BankSettledOutwardTxns from '../views/bank/SettledOutwardTxns.vue'
import BankSettledInwardTxns from '../views/bank/SettledInwardTxns.vue'
import BankNetSettlement from '../views/bank/NetSettlement'
import BankUsers from '../views/bank/Users.vue'
import BankSettledEzOutwardTxns from '../views/bank/SettledEzOutwardTxns.vue'
import BankSettledEzInwardTxns from '../views/bank/SettledEzInwardTxns.vue'

import SuperLiveTcsOutwardTxns from '../views/ghipss/LiveTcsOutwardTxns.vue'
import SuperLiveTcsInwardTxns from '../views/ghipss/LiveTcsInwardTxns.vue'
import SuperArchiveTcsOutwardTxns from '../views/ghipss/ArchiveTcsOutwardTxns.vue'
import SuperArchiveTcsInwardTxns from '../views/ghipss/ArchiveTcsInwardTxns.vue'

import LiveTcsOutwardTxns from '../views/bank/LiveTcsOutwardTxns.vue'
import LiveTcsInwardTxns from '../views/bank/LiveTcsInwardTxns.vue'
import ArchiveTcsOutwardTxns from '../views/bank/ArchiveTcsOutwardTxns.vue'
import ArchiveTcsInwardTxns from '../views/bank/ArchiveTcsInwardTxns.vue'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/password_change',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/super_live_inward',
    name: 'SuperLiveInwardTxns',
    component: SuperLiveInwardTxns
  },
  {
    path: '/super_live_outward',
    name: 'SuperLiveOutwardTxns',
    component: SuperLiveOutwardTxns
  },
  {
    path: '/super_tcs_live_outward',
    name: 'SuperLiveTcsOutwardTxns',
    component: SuperLiveTcsOutwardTxns
  },
  {
    path: '/super_tcs_live_inward',
    name: 'SuperLiveTcsInwardTxns',
    component: SuperLiveTcsInwardTxns
  },
  {
    path: '/super_tcs_archived_outward',
    name: 'SuperArchiveTcsOutwardTxns',
    component: SuperArchiveTcsOutwardTxns
  },
  {
    path: '/super_tcs_archived_inward',
    name: 'SuperArchiveTcsInwardTxns',
    component: SuperArchiveTcsInwardTxns
  },
  {
    path: '/super_settled_outward',
    name: 'SuperSettledOutwardTxns',
    component: SuperSettledOutwardTxns
  },
  {
    path: '/super_settled_inward',
    name: 'SuperSettledInwardTxns',
    component: SuperSettledInwardTxns
  },
  {
    path: '/super_settled_ez_inward',
    name: 'SuperSettledEzInwardTxns',
    component: SuperSettledEzInwardTxns
  },
  {
    path: '/super_settled_ez_outward',
    name: 'SuperSettledEzOutwardTxns',
    component: SuperSettledEzOutwardTxns
  },
  {
    path: '/super_net_position',
    name: 'SuperNetSettlement',
    component: SuperNetSettlement
  },
  {
    path: '/institutions',
    name: 'Institutions',
    component: Institutions
  },
  {
    path: '/super_users',
    name: 'SuperUsers',
    component: SuperUsers
  },
  {
    path: '/super_realtime_txns',
    name: 'SuperRealTimeTxns',
    component: SuperRealTimeTxns
  }, 
  {
    path: '/super_realtime_failures',
    name: 'SuperRealTimeErrors',
    component: SuperRealTimeErrors
  }, 

  
  
  {
    path: '/live_inward',
    name: 'BankLiveInwardTxns',
    component: BankLiveInwardTxns
  },
  {
    path: '/live_outward',
    name: 'BankLiveOutwardTxns',
    component: BankLiveOutwardTxns
  },
  {
    path: '/settled_outward',
    name: 'BankSettledOutwardTxns',
    component: BankSettledOutwardTxns
  },
  {
    path: '/settled_inward',
    name: 'BankSettledInwardTxns',
    component: BankSettledInwardTxns
  },
  {
    path: '/net_position',
    name: 'BankNetSettlement',
    component: BankNetSettlement
  },
  {
    path: '/users',
    name: 'BankUsers',
    component: BankUsers
  },
  {
    path: '/settled_ez_outward',
    name: 'BankSettledEzOutwardTxns',
    component: BankSettledEzOutwardTxns
  },
  {
    path: '/settled_ez_inward',
    name: 'BankSettledEzInwardTxns',
    component: BankSettledEzInwardTxns
  },
  {
    path: '/tcs_live_outward',
    name: 'LiveTcsOutwardTxns',
    component: LiveTcsOutwardTxns
  },
  {
    path: '/tcs_live_inward',
    name: 'LiveTcsInwardTxns',
    component: LiveTcsInwardTxns
  },
  {
    path: '/tcs_archived_outward',
    name: 'ArchiveTcsOutwardTxns',
    component: ArchiveTcsOutwardTxns
  },
  {
    path: '/tcs_archived_inward',
    name: 'ArchiveTcsInwardTxns',
    component: ArchiveTcsInwardTxns
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
