<template>
  <div class="login-page">
    <div class="login-box">
        <div class="logo text-center">
            <a href="javascript:void(0);"><img src="@/assets/GhIPSS-logo.png" style="width:200px" /></a>
            <h2 class="col-ghipss">TransView</h2>
        </div>
        <div class="card">
          <div class="body">
            <form @submit.prevent="performLogin">
              <div class="msg">Sign in to start your session</div>
                <div class="input-group">
                  <span class="input-group-addon">
                    <i class="material-icons">email</i>
                  </span>
                  <div class="form-line">
                    <input type="text" class="form-control" v-model="username" placeholder="Email address" autofocus 
                    :class="{'is-invalid': v$.username.$error, 'is-valid': !v$.username.$invalid }">
                  </div>
                    <span v-if="v$.username.$error" class="text-danger">
                      {{ v$.username.$errors[0].$message }}
                    </span>                  
                </div>

                <div class="input-group">
                  <span class="input-group-addon">
                    <i class="material-icons">lock</i>
                  </span>
                  <div class="form-line">
                    <input :type="passwordVisibility.passwordFieldType" v-model="password" class="form-control" placeholder="Password" 
                    :class="{'is-invalid': v$.password.$error, 'is-valid': !v$.password.$invalid }">
                  </div>
                      <span v-if="v$.password.$error" class="text-danger" style="width:100%">
                        {{ v$.password.$errors[0].$message }}
                      </span> 
                    <span class="input-group-addon" @click="switchVisibility">
                      <i :class="passwordVisibility.passwordFieldIcon"></i>
                    </span>

           
                </div>

                <div class="row">
                  <div class="col-xs-6">
                    <button class="btn btn-block btn-ghipss waves-effect">SIGN IN</button>
                  </div>
                <!-- </div>
                <div class="row m-t-15 m-b--20"> -->
                  <div class="col-xs-6 pull-right">
                    <router-link to="/forgot_password"><span class="col-ghipss pull-right">Forgot Password?</span></router-link>
                  </div>

                </div>
                <img src="@/assets/background-img.jpg" class="login-illus" alt="">

            </form>
          </div>
        </div>
    </div>

  </div>
</template>

<style scoped>
  .login-page{
    background: none;
  }  
</style>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },  
  name: 'Login',
  components: {

  },
  data(){
    return{
      username: '',
      password: '',
      userDetails: {},
      passwordVisibility: {
        passwordFieldType: "password",
        passwordFieldIcon: "fa fa-eye"
      }
    }
  },
  validations() {
    return{
      username: {
        required,
        email
      },
      password: {
        required,
      }
    }
  },  
  methods:{
    switchVisibility() {
      this.passwordVisibility.passwordFieldType = this.passwordVisibility.passwordFieldType === "password" ? "text" : "password";
      this.passwordVisibility.passwordFieldIcon = this.passwordVisibility.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
    },

      async performLogin() {
        this.v$.$validate() 
        if (!this.v$.$error) { 
          $.LoadingOverlay("show");

          try {
              const data = {
                username: this.username,
                password: this.password
              }
              const response = await axios.post('user/authenticate_user', data);
              if(response.status == 200){
                this.userDetails = response.data;

                if(this.userDetails.generalResponse.errorCode == '0'){
                  //check if user is active
                  if(this.userDetails.userDTO.active){
                    //check if user's bank is active
                    if(this.userDetails.userDTO.bankStatus){
                      //check if status is activated (after password change)
                      if(this.userDetails.userDTO.status == "1"){
                        localStorage.setItem('userid', this.userDetails.userDTO.id);
                        localStorage.setItem('email', this.userDetails.userDTO.emailAddress);
                        localStorage.setItem('bankcode', this.userDetails.userDTO.bank);
                        localStorage.setItem('bankname', this.userDetails.userDTO.bankName);
                        localStorage.setItem('fullname', this.userDetails.userDTO.fullName);
                        localStorage.setItem('rolecode', this.userDetails.userDTO.role);
                        localStorage.setItem('rolename', this.userDetails.userDTO.roleName);
                        localStorage.setItem('status', this.userDetails.userDTO.status);
                        localStorage.setItem('active', this.userDetails.userDTO.active);

                        if(this.userDetails.userDTO.bank == '000001'){
                          //ghipss
                          this.$router.push('/super_live_outward');
                        }else{
                          //banks
                          this.$router.push('/live_outward');
                        }

                      }else{
                        localStorage.setItem('email', this.userDetails.userDTO.emailAddress);
                        localStorage.setItem('userid', this.userDetails.userDTO.id);
                        localStorage.setItem('fullname', this.userDetails.userDTO.fullName);

                      
                        this.$router.push('/password_change')
                      }
                    }else{
                      this.$swal('Failed', "Assigned institution is inactive. Please contact the administrator!", 'error')                  

                    }
                
                  }else{
                    this.$swal('Failed', "User account is inactive!", 'error')                  
                  }
                  
                }else{
                  this.$swal('Failed', this.userDetails.generalResponse.errorMessage, 'error')                  
                }
              }else{
                this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                
              }
            // console.log(response);
          }
          catch(err) {
              this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')     
              console.log(err)           

          }finally{
            $.LoadingOverlay("hide");
            this.username = '';
            this.password = '';
            this.v$.$reset();

          }
          
        }
    },
    

  },
  mounted() {
    localStorage.clear();

  }

}
</script>
