<template>
    <aside id="rightsidebar" class="right-sidebar">
        <div class="tab-content">
            <div role="tabpanel" class="tab-pane fade in active in active" id="settings">
                <div class="demo-settings">
                    <p>PERSONAL INFO</p>
                    <ul class="setting-list">
                        <li>
                            <span><b>Full Name:</b> {{ loginFullName }} </span>

                        </li>
                        <li>
                            <span><b>Email:</b> {{ loginEmail }} </span>

                        </li>
                        <li>
                            <span><b>Role:</b> {{ loginRole }} </span>

                        </li>
                    </ul>
                    <p>BANK INFO</p>
                    <ul class="setting-list">
                        <li>
                            <span><b>FI Code:</b> {{ loginBankCode }} </span>
                        </li>
                        <li>
                            <span><b>Bank Name:</b> {{ loginBankName }} </span>
                        </li>

                    </ul>

                </div>
            </div>
        </div>
    </aside>    
</template>
<style scoped>

</style>
<script>
export default {
    name: 'RightNav',
    components: {

    },
    data() {
        return {
            loginFullName: '',
            loginEmail: '',
            loginRole: '',
            loginBankCode: '',
            loginBankName: ''
        }
    },
    mounted(){
        this.loginFullName = localStorage.getItem('fullname')
        this.loginEmail = localStorage.getItem('email')
        this.loginRole = localStorage.getItem('rolename')
        this.loginBankCode = localStorage.getItem('bankcode')
        this.loginBankName = localStorage.getItem('bankname')

    }
}
</script>