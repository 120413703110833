<template>
    <div class="login-page" >
        <div class="login-box">
            <div class="logo text-center">
                <a href="javascript:void(0);"><img src="@/assets/GhIPSS-logo.png" style="width:200px" /></a>
                <h2 class="col-ghipss">TransView</h2>
            </div>
            <div class="card">
                <div class="body">
                    <form @submit.prevent="performPasswordChange">
                        <div class="msg">Change Your Password</div>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">lock</i>
                            </span>
                            <div class="form-line">
                                <input :type="passwordVisibitity.passwordFieldType" class="form-control" v-model="currentPassword" placeholder="Current password" autofocus
                                :class="{'is-invalid': v$.currentPassword.$error, 'is-valid': !v$.currentPassword.$invalid }">
                            </div>
                            <span v-if="v$.currentPassword.$error" class="text-danger">
                            {{ v$.currentPassword.$errors[0].$message }}
                            </span>                            
                            <span class="input-group-addon" @click="switchVisibility">
                                <i :class="passwordVisibitity.passwordFieldIcon"></i>
                            </span>
                        </div>

                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">lock</i>
                            </span>
                            <div class="form-line">
                                <input :type="passwordVisibitity1.passwordFieldType" class="form-control" v-model="newPassword" placeholder="New Password"
                                :class="{'is-invalid': v$.newPassword.$error, 'is-valid': !v$.newPassword.$invalid }">
                            </div>
                            <span v-if="v$.newPassword.$error" class="text-danger">
                            {{ v$.newPassword.$errors[0].$message }}
                            </span>                            
                            <span class="input-group-addon" @click="switchVisibility1">
                                <i :class="passwordVisibitity1.passwordFieldIcon"></i>
                            </span>                            
                        </div>

                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">lock</i>
                            </span>
                            <div class="form-line">
                                <input :type="passwordVisibitity2.passwordFieldType" class="form-control" v-model="repeatPassword" placeholder="Repeat New Password"
                                :class="{'is-invalid': v$.repeatPassword.$error, 'is-valid': !v$.repeatPassword.$invalid }">
                            </div>
                            <span v-if="v$.repeatPassword.$error" class="text-danger">
                            {{ v$.repeatPassword.$errors[0].$message }}
                            </span>                            
                            <span class="input-group-addon" @click="switchVisibility2">
                                <i :class="passwordVisibitity2.passwordFieldIcon"></i>
                            </span>                            
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <button class="btn btn-block btn-ghipss waves-effect">Change</button>
                            </div>
                            <div class="col-xs-6 pull-right">
                                <router-link to="/"><span class="col-ghipss pull-right">Back to login!</span></router-link>
                            </div>

                        </div>
                        <img src="@/assets/background-img.jpg" class="login-illus" alt="">

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
  .login-page{
    background: none;
  }  
</style>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'

export default {

  name: 'ChangePassword',
  components: {

  },
  data() {
      return {
        v$: useVuelidate(),
        username: '',
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
           
        generalResponse: {},
        passwordVisibitity: {
            passwordFieldType: "password",
            passwordFieldIcon: "fa fa-eye"
             
        },
        passwordVisibitity1: {
            passwordFieldType: "password",
            passwordFieldIcon: "fa fa-eye"
             
        },
        passwordVisibitity2: {
            passwordFieldType: "password",
            passwordFieldIcon: "fa fa-eye"
             
        }            
      }
  },
    validations() {
      return{
        currentPassword: {
          required,
        },
        newPassword: {
          required,
        },
        repeatPassword: {
          required,
          sameAs: sameAs(this.newPassword)
        },
      }
    },  
  methods: {
      switchVisibility() {
        this.passwordVisibitity.passwordFieldType = this.passwordVisibitity.passwordFieldType === "password" ? "text" : "password";
        this.passwordVisibitity.passwordFieldIcon = this.passwordVisibitity.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
      },

      switchVisibility1() {
        this.passwordVisibitity1.passwordFieldType = this.passwordVisibitity1.passwordFieldType === "password" ? "text" : "password";
        this.passwordVisibitity1.passwordFieldIcon = this.passwordVisibitity1.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
      },

      switchVisibility2() {
        this.passwordVisibitity2.passwordFieldType = this.passwordVisibitity2.passwordFieldType === "password" ? "text" : "password";
        this.passwordVisibitity2.passwordFieldIcon = this.passwordVisibitity2.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
      }, 
      
      async performPasswordChange() {
        // console.log(this.v$)
        this.v$.$validate();      
        this.v$.currentPassword.$touch;
        this.v$.newPassword.$touch;
        this.v$.repeatPassword.$touch;

        if (!this.v$.currentPassword.$invalid && !this.v$.newPassword.$invalid && !this.v$.repeatPassword.$invalid) { 

          $.LoadingOverlay("show");

          this.username = localStorage.getItem('email');
          try {
              const data = {
                username: this.username,
                oldPassword: this.currentPassword,
                newPassword: this.newPassword
              }
              const response = await axios.post('user/change_self_password', data);
              if(response.status == 200){
                this.generalResponse = response.data;
                if(this.generalResponse.errorCode == '0'){
                    this.$swal('Success', 'Password successfully changed!', 'success')                
                    this.$router.push('/')

                }else{
                  this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                }
              }else{
                this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

              }
          }
          catch(err) {
              this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

          }finally{
            $.LoadingOverlay("hide");
            this.username = '';
            this.currentPassword = '';
            this.newPassword = '';
            this.repeatPassword = '';
            this.v$.$reset();

          }
          
        }
    },      
  }

}
</script>