<template>
    <aside id="leftsidebar" class="sidebar">
        <div class="menu">
            <ul class="list" v-if="who_bank == '000001'">
                <li class="header">MAIN NAVIGATION</li>
                <!-- <li>
                    <a href="javascript:void(0);" class="menu-toggle">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Transactions</span>
                    </a>
                    <ul class="ml-menu"> -->
                        <!-- for support && (support && recon) && super admin -->
                        <li :class="{active:$route.name == 'SuperLiveOutwardTxns' || $route.name == 'SuperLiveInwardTxns'}"
                            v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                            <a href="javascript:void(0);" class="menu-toggle">
                                <i class="material-icons col-ghipss">donut_large</i>
                                <span>Live Transactions</span>
                            </a>
                            <ul class="ml-menu">
                                <li :class="{active:$route.name == 'SuperLiveOutwardTxns'}">
                                    <router-link to="/super_live_outward">Outward</router-link>
                                </li>
                                <li :class="{active:$route.name == 'SuperLiveInwardTxns'}">
                                    <router-link to="/super_live_inward">Inward</router-link>
                                </li>
                            </ul>
                        </li>
                        <!-- for support && (support && recon) && super admin -->                        
                        <li :class="{active:$route.name == 'SuperSettledOutwardTxns' || $route.name == 'SuperSettledInwardTxns' || $route.name == 'SuperSettledEzInwardTxns' || $route.name == 'SuperSettledEzOutwardTxns'}"
                            v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                            <a href="javascript:void(0);" class="menu-toggle">
                                <i class="material-icons col-ghipss">donut_large</i>
                                <span>Settled Transactions</span>
                            </a>
                            <ul class="ml-menu">
                                <li :class="{active:$route.name == 'SuperSettledOutwardTxns'}">
                                    <router-link to="/super_settled_outward">Outward</router-link>
                                </li>
                                <li :class="{active:$route.name == 'SuperSettledInwardTxns'}">
                                    <router-link to="/super_settled_inward">Inward</router-link>
                                </li>
                                
                                <li :class="{active:$route.name == 'SuperSettledEzInwardTxns'}" v-if="who_bank=='000001' || who_bank.startsWith('3005')">
                                    <router-link to="/super_settled_ez_inward">C2M</router-link>
                                </li>
                                <li :class="{active:$route.name == 'SuperSettledEzOutwardTxns'}" v-if="who_bank=='000001' || who_bank.startsWith('3005')">
                                    <router-link to="/super_settled_ez_outward">M2C</router-link>
                                </li>  
                                
                              
                            </ul>
                        </li>     
                        <!-- for support && (support && recon) && super admin -->                        
                        <li :class="{active:$route.name == 'SuperLiveTcsOutwardTxns' || $route.name == 'SuperLiveTcsInwardTxns' || $route.name == 'SuperArchiveTcsOutwardTxns' || $route.name == 'SuperArchiveTcsInwardTxns'}"
                            v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                            <a href="javascript:void(0);" class="menu-toggle">
                                <i class="material-icons col-ghipss">donut_large</i>
                                <span>TCS</span>
                            </a>
                            <ul class="ml-menu">
                                <li :class="{active:$route.name == 'SuperLiveTcsOutwardTxns'}">
                                    <router-link to="/super_tcs_live_outward">Live Outward</router-link>
                                </li>
                                <li :class="{active:$route.name == 'SuperLiveTcsInwardTxns'}">
                                    <router-link to="/super_tcs_live_inward">Live Inward</router-link>
                                </li>
                                
                                <li :class="{active:$route.name == 'SuperArchiveTcsOutwardTxns'}">
                                    <router-link to="/super_tcs_archived_outward">Archived Outward</router-link>
                                </li>
                                <li :class="{active:$route.name == 'SuperArchiveTcsInwardTxns'}">
                                    <router-link to="/super_tcs_archived_inward">Archived Inward</router-link>
                                </li>  
                                
                              
                            </ul>
                        </li>                                            
                    <!-- </ul>
                </li> -->
                <!-- for finance and super admin -->
                <li :class="{active:$route.name == 'SuperNetSettlement'}"
                    v-if="who_role=='5' || who_role=='100'">
                    <router-link to="/super_net_position">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Net Position Report</span>
                    </router-link>
                </li>

                <!-- for every role but admin -->
                <li :class="{active:$route.name == 'SuperRealTimeTxns'}"
                    v-if="who_role=='1' || who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                    <router-link to="/super_realtime_txns">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Real-Time Transactions</span>
                    </router-link>
                </li>

                <!-- for every role but admin -->
                <li :class="{active:$route.name == 'SuperRealTimeErrors'}"
                    v-if="who_role=='1' || who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                    <router-link to="/super_realtime_failures">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Real-Time Failures</span>
                    </router-link>
                </li>

                <!-- for admin && super admin -->
                <li :class="{active:$route.name == 'Institutions'}"
                    v-if="who_role=='4' || who_role=='100'">
                    <router-link to="/institutions">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Institutions</span>
                    </router-link>
                </li>
                <!-- for admin && super admin -->
                <li :class="{active:$route.name == 'SuperUsers'}"
                    v-if="who_role=='4' || who_role=='100'">
                    <router-link to="/super_users">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Users</span>
                    </router-link>
                </li>

            </ul>

            <ul class="list" v-else>
                <li class="header">MAIN NAVIGATION</li>
                <!-- for support && (support && recon) && super admin -->
                <li :class="{active:$route.name == 'BankLiveOutwardTxns' || $route.name == 'BankLiveInwardTxns'}"
                    v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                    <a href="javascript:void(0);" class="menu-toggle">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Live Transactions</span>
                    </a>
                    <ul class="ml-menu">
                        <li :class="{active:$route.name == 'BankLiveOutwardTxns'}">
                            <router-link to="/live_outward">Outward</router-link>
                        </li>
                        <li :class="{active:$route.name == 'BankLiveInwardTxns'}">
                            <router-link to="/live_inward">Inward</router-link>
                        </li>
                    </ul>
                </li>
                <!-- for support && (support && recon) && super admin -->
                <li :class="{active:$route.name == 'BankSettledOutwardTxns' || $route.name == 'BankSettledInwardTxns' || $route.name == 'BankSettledEzInwardTxns' || $route.name == 'BankSettledEzOutwardTxns'}"
                    v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                    <a href="javascript:void(0);" class="menu-toggle">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Settled Transactions</span>
                    </a>
                    <ul class="ml-menu">
                        <li :class="{active:$route.name == 'BankSettledOutwardTxns'}">
                            <router-link to="/settled_outward">Outward</router-link>
                        </li>
                        <li :class="{active:$route.name == 'BankSettledInwardTxns'}">
                            <router-link to="/settled_inward">Inward</router-link>
                        </li>

                        <li :class="{active:$route.name == 'BankSettledEzInwardTxns'}" v-if="who_bank=='000001' || who_bank.startsWith('3005')">
                            <router-link to="/settled_ez_inward">C2M</router-link>
                        </li>
                        <li :class="{active:$route.name == 'BankSettledEzOutwardTxns'}" v-if="who_bank=='000001' || who_bank.startsWith('3005')">
                            <router-link to="/settled_ez_outward">M2C</router-link>
                        </li>                          
                    </ul>
                </li>  
                <!-- for support && (support && recon) && super admin -->                        
                <li :class="{active:$route.name == 'LiveTcsOutwardTxns' || $route.name == 'LiveTcsInwardTxns' || $route.name == 'ArchiveTcsOutwardTxns' || $route.name == 'ArchiveTcsInwardTxns'}"
                    v-if="who_role=='2' || who_role=='3' || who_role=='5' || who_role=='100'">
                        <a href="javascript:void(0);" class="menu-toggle">
                            <i class="material-icons col-ghipss">donut_large</i>
                            <span>TCS</span>
                        </a>
                        <ul class="ml-menu">
                            <li :class="{active:$route.name == 'LiveTcsOutwardTxns'}">
                                <router-link to="/tcs_live_outward">Live Outward</router-link>
                            </li>
                            <li :class="{active:$route.name == 'LiveTcsInwardTxns'}">
                                <router-link to="/tcs_live_inward">Live Inward</router-link>
                            </li>
                                
                            <li :class="{active:$route.name == 'ArchiveTcsOutwardTxns'}">
                                <router-link to="/tcs_archived_outward">Archived Outward</router-link>
                            </li>
                            <li :class="{active:$route.name == 'ArchiveTcsInwardTxns'}">
                                <router-link to="/tcs_archived_inward">Archived Inward</router-link>
                            </li>  
                                      
                        </ul>
                </li>                                       
                <!-- for finance and super admin -->
                <li :class="{active:$route.name == 'BankNetSettlement'}" 
                    v-if="who_role=='5'">
                    <router-link to="/net_position">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Net Position Report</span>
                    </router-link>
                </li>
                <!-- for admin && super admin -->
                <li :class="{active:$route.name == 'BankUsers'}"
                    v-if="who_role=='4' || who_role=='100'">
                    <router-link to="/users">
                        <i class="material-icons col-ghipss">donut_large</i>
                        <span>Users</span>
                    </router-link>
                </li>


            </ul>            
        </div>
        <!-- #Menu -->
        <!-- Footer -->
        <div class="legal">
            <div class="copyright">
                &copy; 2021 <a href="javascript:void(0);"><span class="col-ghipss">GhIPSS - TransView</span></a>.
            </div>
            <div class="version">
                <b>Version: </b> 2.0
            </div>
        </div>
        <!-- #Footer -->
    </aside>
    
</template>
<style scoped>

</style>
<script>
export default {
    name: 'LeftNav',
    components: {

    },
    data() {
        return {
            who_bank: '',
            who_role: ''
        }
    },
    methods: {

    },
    mounted(){
        this.who_bank = localStorage.getItem('bankcode')
        this.who_role = localStorage.getItem('rolecode')

    }
}
</script>