<template>
    <div class="theme-ghipss">
        <div class="overlay"></div>
            <top-nav />
        <section>
            <left-nav />
            <right-nav />
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="block-header">
                    <h2>NET POSITION REPORTS</h2>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 m-t-5">
                        <vue-select
                            v-model="periodId"
                            label-by="name"
                            value-by="id"
                            searchable
                            close-on-select
                            :options="allTimeRanges"
                            @selected="handleSearchInput"
                            placeholder="select time range"
                        />
                        <span v-if="v$.periodId.$error" class="text-danger">
                            {{ v$.periodId.$errors[0].$message }}
                        </span>                         
                    </div>

                                      
                    <div class=" col-md-4 col-sm-6 col-xs-12 m-t-5">
                        <span v-if="!isDownloading">
                            <button @click.prevent="generateNetReport" class="btn btn-ghipss waves-effect">Generate</button>
                        </span>
                        <span v-else><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> busy</span>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-t-15" id="txn_table_div">
                        <div class="card">
                            <div class="row header">
                                <div class="pull-left">
                                    <h2 v-show="firstSearch">
                                        <span> {{ showSelectedDetails }}</span>
                                    </h2>
                                </div>
                                <!-- <div class="pull-right" style="margin:-10px 0px 0px 0px" v-show="firstSearch">
                                    <button type="button" class="btn btn-default btn-circle waves-effect waves-circle waves-float" @click="getLiveOutwardTransactionsByBank">
                                        <i class="material-icons">cached</i>
                                    </button>                  
                                    <i class="far fa-clock" style="margin-left:10px"></i> {{ lastUpdateTime }}
                                </div> -->
                            </div>
                            <div class="row body">
                                <div class="container-fluid">
                                    <div class="pull-left">
                                        <p>
                                            <!-- Excel -->
                                        </p>
                                    </div>
                                    <div class="pull-right">
                                        <div class="form-group" style="margin:-10px 0px 0px 0px">
                                            <div class="form-line">
                                                <input type="text" placeholder="Search here..." v-model="searchInput" class="form-control" @input="searchEvent" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Period</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                                <th>Inst. Code</th>
                                                <th>Created At</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="filteredEntries.length > 0">
                                            <tr v-for="(transaction, i) in filteredEntries" :key="i">
                                                <td>{{ transaction.period }}</td>
                                                <td>{{ transaction.debit_value }}</td>
                                                <td>{{ transaction.credit_value }}</td>
                                                <td>{{ transaction.fi_code }}</td>
                                                <td>{{ transaction.date_create }}</td>
                                                <td>
                                                    <a class="btn btn-ghipss btn-circle waves-effect waves-circle waves-float m-l-5" @click="netId = transaction.id; downloadNetReport()">
                                                        <i class="material-icons">file_download</i>
                                                    </a>
                                                    <a class="btn btn-success btn-circle waves-effect waves-circle waves-float m-l-5" @click="report = {}; report = transaction; sendReportMail()">
                                                        <i class="material-icons">send</i>
                                                    </a>
                                                </td>                                                
                                                <!-- <td>
                                                    <a class="btn btn-ghipss btn-circle waves-effect waves-circle waves-float m-l-5" :href="transaction.file_path" target="_blank">
                                                        <i class="material-icons">file_download</i>
                                                    </a>
                                                </td> -->

                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="6" class="text-center">No records</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 10px 20px">
                                            <span class="pagination">
                                                showing {{ showInfo.from }} to {{ showInfo.to }} of {{ showInfo.of }} entries
                                            </span>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <ul class="pagination pull-right">
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>                      
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="currentPage < 1 ? currentPage = 1 : currentPage -= 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li v-for="pagi in showPagination" :key="pagi" :class="['page-item', {'disabled': pagi === '...', 'active': pagi === currentPage}]">
                                                    <a href="#" class="page-link" @click.prevent="paginateEvent(pagi)">{{ pagi }}</a>
                                                </li>                    
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a class="page-link" href="#" aria-label="Next" @click.prevent="currentPage > allPages ? currentPage = allPages : currentPage += 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = allPages, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>            
                                                    </a>
                                                </li>                    
                                            </ul>            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>
    </div>
</template>
<style scoped>

</style>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'


import axios from 'axios';
import TopNav from "@/components/TopNav.vue";
import LeftNav from "@/components/LeftNav.vue";
import RightNav from "@/components/RightNav.vue";

import VueSelect from 'vue-next-select'
import '../../../public/css/vue-next-select-refactored.css'

import { $array } from 'alga-js';
import moment from "moment";


import SockJS from "sockjs-client";
import Stomp from "webstomp-client";


export default {
    name: 'BankNetSettlement',
    components:{
        TopNav,
        LeftNav,
        RightNav,
        VueSelect
    },
    data() {
       
        return {
            v$: useVuelidate(),            
            connection: null,

            allTimeRanges: [],
            periodId:'',
            allBanks: [],
            bankId: '',
            fiName: '',   
            secBankId: '',         
            secBankName: '',            
            transactions: [],
            report: {},
            isDownloading: false,
            firstSearch: false,
            lastUpdateTime: '',
            generalResponse: {},
            netId:0,

            entries:[],
            showEntries:[5, 10, 15, 25, 50, 75, 100],
            currentEntries: 10,
            filteredEntries: [],
            currentPage: 1,
            allPages: 1,
            searchInput: '',
            searchEntries: [],

        }
    },
    validations() {
      return{

        periodId: {
          required,
        },
             
      }
    },
    methods: {

        async downloadNetReport(){
            this.isDownloading = true;
            let id = this.netId;


            axios({
                url: `report/download_net_report/${id}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
            if (response.status == 200) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.bankId+'_net_settlement_'+moment(new Date()).format("YYYYMMDD_HHmmss")+'.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();
            }else{
                this.$swal('Failed', 'Error downloading report!', 'error')                
            }
            }).catch((err)=>{
                this.$swal('Failed', 'Error downloading report!', 'error')                

            }).finally(()=>{
            this.isDownloading = false;

            });
        },

        async getAllBanks(){
          const response = await axios.get("bank/get_banks");
          if(response.status == 200){
            this.allBanks = response.data;
          }
        },

        async generateNetReport() {
            this.v$.$validate();      
            this.v$.periodId.$touch;

            if (!this.v$.periodId.$invalid) {             
                try{
                    $("#txn_table_div").LoadingOverlay("show");
                    this.isDownloading = true;

                const response = await axios.get(`/report/generate_net_settlement_summary_report/${localStorage.getItem('fullname')}/${this.bankId}/${this.periodId}/${this.bankId}`);
                if (response.status == 200) {
                    this.generalResponse = response.data;
                    if(this.generalResponse.errorCode == "0"){
                        this.$swal('Success', this.generalResponse.errorMessage, 'success')                

                    }else{
                        this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                    }
                }else{
                    this.$swal('Failed', 'Error sending request!', 'error')                

                }
                }catch(err){
                    this.$swal('Failed', 'An unexpected error occurred!', 'error')                

                }finally{
                    $("#txn_table_div").LoadingOverlay("hide");
                    this.isDownloading = false;
                }
            }
        },

        async getNetSettlementReports(value) {
            try{
                $("#txn_table_div").LoadingOverlay("show");
                this.isDownloading = true;

            const response = await axios.get(`report/get_summary_net_settlements_by_ficode/${this.bankId}`);
            if (response.status == 200) {
                this.transactions = response.data;
                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                this.allPages = $array.pages(this.transactions, this.currentEntries)   

            }else{
                this.$swal('Failed', 'Error loading records!', 'error')                

            }
            }catch(err){
                this.$swal('Failed', 'Error loading records!', 'error')                

            }finally{
                $("#txn_table_div").LoadingOverlay("hide");
                this.isDownloading = false;
            }
        },

        async sendReportMail() {
            this.$swal({
            title: 'Send Mail ( '+ this.report.period +' )',
            text: 'Are you sure you want to send this report via mail',
            // type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No!',
            showCloseButton: true,
            showLoaderOnConfirm: true
            }).then((result) => {
            if(result.value) {
                axios.post('notification/send_net_settlement_mail/'+this.report.fi_code+'/'+this.report.period)
                    .then(res => {
                        if(res.data.errorCode == '0'){
                            this.$swal('Success', 'Mail successfully sent!', 'success')

                        }else{
                            this.$swal('Failed', res.data.errorMessage, 'error')
                        }
                    }).catch(err => {
                        this.$swal('Failed', 'Mail could not be sent. Try again!', 'error')
                    });
            } 

            })
        },
        
        paginateEntries(){
            if(this.searchInput.length >= 1){
                this.searchEntries = $array.search(this.searchInput)(this.transactions)
                this.filteredEntries = $array.paginate(this.searchEntries)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.searchEntries, this.currentEntries)

            }else{
                this.searchEntries = []
                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.transactions, this.currentEntries)
            
            }
        },

        paginateEvent(page){
            this.currentPage = page;
            this.paginateEntries();
        },
    
        searchEvent(){
            this.currentPage = 1
            this.paginateEntries()
        },   
        
        connect() {
            let socket = new SockJS("/transview");
            // let socket = new SockJS("http://127.0.0.1:8080/transview");

            this.stompClient = Stomp.over(socket);
            this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
            this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
            this.stompClient.reconnect_delay = 5000;     // client reconnect time in case of network issue
            this.stompClient.connect({},
                frame => {
                this.connected = true;
                // console.log(frame);
                    this.stompClient.subscribe('/topic/'+localStorage.getItem('bankcode'), result => {
                        // this.received_messages.push(JSON.parse(result.body).content);
                        this.getNetSettlementReports()
                        
                    });
                },
                error => {
                    // console.log(error);
                    this.connected = false;
                }
            );


        },
    
        disconnect() {
            if (this.stompClient) {
                this.stompClient.disconnect();
            }
            this.connected = false;
        },        

    },

    mounted() {
        if(localStorage.getItem('bankcode') != '000001'){
            if(localStorage.getItem('rolecode') != '5'){
                    this.$router.push('/')
            }else{

                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', '/js/admin.js')
                document.body.appendChild(externalScript)
                this.moment = moment;

                this.allTimeRanges = [
                        {
                            id: '01:00:00',
                            name: '00:00:00 - 01:00:00'
                        },
                        {
                            id: '02:00:00',
                            name: '00:00:00 - 02:00:00'
                        },
                        {
                            id: '03:00:00',
                            name: '00:00:00 - 03:00:00'
                        },
                        {
                            id: '04:00:00',
                            name: '00:00:00 - 04:00:00'
                        },
                        {
                            id: '05:00:00',
                            name: '00:00:00 - 05:00:00'
                        },
                        {
                            id: '06:00:00',
                            name: '00:00:00 - 06:00:00'
                        },
                        {
                            id: '07:00:00',
                            name: '00:00:00 - 07:00:00'
                        },
                        {
                            id: '08:00:00',
                            name: '00:00:00 - 08:00:00'
                        },
                        {
                            id: '09:00:00',
                            name: '00:00:00 - 09:00:00'
                        },
                        {
                            id: '10:00:00',
                            name: '00:00:00 - 10:00:00'
                        },
                        {
                            id: '11:00:00',
                            name: '00:00:00 - 11:00:00'
                        },
                        {
                            id: '12:00:00',
                            name: '00:00:00 - 12:00:00'
                        },
                        {
                            id: '13:00:00',
                            name: '00:00:00 - 13:00:00'
                        },
                        {
                            id: '14:00:00',
                            name: '00:00:00 - 14:00:00'
                        },
                        {
                            id: '15:00:00',
                            name: '00:00:00 - 15:00:00'
                        },
                        {
                            id: '16:00:00',
                            name: '00:00:00 - 16:00:00'
                        },
                        {
                            id: '17:00:00',
                            name: '00:00:00 - 17:00:00'
                        },
                        {
                            id: '18:00:00',
                            name: '00:00:00 - 18:00:00'
                        },
                        {
                            id: '19:00:00',
                            name: '00:00:00 - 19:00:00'
                        },
                        {
                            id: '20:00:00',
                            name: '00:00:00 - 20:00:00'
                        },
                        {
                            id: '21:00:00',
                            name: '00:00:00 - 21:00:00'
                        },
                        {
                            id: '22:00:00',
                            name: '00:00:00 - 22:00:00'
                        },
                        {
                            id: '23:00:00',
                            name: '00:00:00 - 23:00:00'
                        },

                ]

                this.bankId = localStorage.getItem('bankcode')

                // this.getAllBanks();
                this.getNetSettlementReports();
                this.connect();

            }
        }else{
            this.$router.push('/');

        }


    },

    computed: {
        showSelectedDetails(){
            // return this.secBankId+' ( '+this.startDate+' - '+this.endDate+' )'
            return this.secBankId
        },

        showInfo() {
            const getCurrentEntries = this.searchEntries.length <= 0 ? this.transactions : this.searchEntries
            return $array.show(getCurrentEntries)(this.currentPage, this.currentEntries)
        },
        
        showPagination(){
            return $array.pagination(this.allPages, this.currentPage, 1)
        },

    },   
    
    beforeUnmount () {
        this.disconnect()

    },    


}
</script>