<template>
    <nav class="navbar">
        <div class="container-fluid">
            <div class="navbar-header">
                <a href="javascript:void(0);" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false"></a>
                <a href="javascript:void(0);" class="bars"></a>
                <a class="navbar-brand" href="javascript:void(0);"><b>GhIPSS TransView</b></a>

            </div>

            <div class="collapse navbar-collapse" id="navbar-collapse">
                <ul class="nav navbar-nav navbar-right">

                <li><a href="javascript:void(0);"><i> {{ getLoggedInName }} </i></a></li>
                    <li><a>|</a></li>
                    <li><a href="#" @click="logout"><i class="material-icons">input</i></a></li>

                    <li class="pull-right"><a href="javascript:void(0);" class="js-right-sidebar" data-close="true"><i class="material-icons">more_vert</i></a></li>
                </ul>
            </div>
        </div>
    </nav>    
</template>
<style scoped>

</style>
<script>
export default {
    name:'TopNav',
    components: {

    },
    data() {
        return {

        }
    },
    methods: {
        async logout(){
            localStorage.clear();
            this.$router.push("/");
        }
    },
  created() {

    if (localStorage.getItem("email") === null || localStorage.getItem("email") === '') {
      this.$router.push('/')
    }
  },

  mounted() {
  
  },

  computed:{
    getLoggedInName(){
      return localStorage.getItem("fullname")+ ' ( '+localStorage.getItem("rolename")+' )'; ;
    }
  }

}
</script>