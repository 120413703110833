<template>
    <div class="theme-ghipss">
        <div class="overlay"></div>
            <top-nav />
        <section>
            <left-nav />
            <right-nav />
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="block-header">
                    <h2>REAL-TIME TRANSACTIONS</h2>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 m-t-5">
                        <vue-select
                            v-model="bankId"
                            label-by="bankName"
                            value-by="fiCode"
                            searchable
                            close-on-select
                            :options="allBanks"
                            @selected="handleSearchInput"
                            placeholder="select an institution"
                        />
                        <span v-if="v$.bankId.$error" class="text-danger">
                            {{ v$.bankId.$errors[0].$message }}
                        </span>                         
                    </div> 

                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 m-t-5" v-show="bankId != '000001'">
                        <vue-select
                            v-model="transType"
                            label-by="name"
                            value-by="id"
                            searchable
                            close-on-select
                            :options="transTypes"
                            placeholder="select Direction"
                        />
                        <span v-if="v$.transType.$error" class="text-danger">
                            {{ v$.transType.$errors[0].$message }}
                        </span>                         
                    </div>
                    
                    <div class=" col-md-4 col-sm-6 col-xs-12 m-t-5">
                        <span v-if="!isConnected">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-ghipss waves-effect" @click.prevent="connect">Connect</button>
                            </div>
                            <!-- <button @click.prevent="connect()" class="btn btn-ghipss waves-effect">Connect</button> -->
                        </span>
                        <span v-else>
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-danger waves-effect" @click.prevent="disconnect">Disconnect</button>
                                <button type="button" class="btn btn-default waves-effect" @click.prevent="reconnect">Reset</button>
                            </div>
                            <!-- <button @click.prevent="disconnect()" class="btn btn-danger waves-effect">Disconnect</button> -->
                        </span>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-t-15" id="txn_table_div">
                        <div class="card">
                            <div class="row header">
                                <div class="pull-left">
                                    <h2 v-show="firstSearch">
                                        <span> {{ showSelectedDetails }}</span>
                                    </h2>
                                </div>
                                <!-- <div class="pull-right" style="margin:-10px 0px 0px 0px" v-show="firstSearch">
                                    <button type="button" class="btn btn-default btn-circle waves-effect waves-circle waves-float" @click="getLiveOutwardTransactionsByBank">
                                        <i class="material-icons">cached</i>
                                    </button>                  
                                    <i class="far fa-clock" style="margin-left:10px"></i> {{ lastUpdateTime }}
                                </div> -->
                            </div>
                            <div class="row body">
                                <div class="container-fluid">
                                    <div class="pull-left">
                                        <p>
                                            <!-- Excel -->
                                        </p>
                                    </div>
                                    <div class="pull-right">
                                        <div class="form-group" style="margin:-10px 0px 0px 0px">
                                            <div class="form-line">
                                                <input type="text" placeholder="Search here..." v-model="searchInput" class="form-control" @input="searchEvent" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Acquirer Bank</th>
                                                <th>Issuer Bank</th>
                                                <th>Trans. Type</th>
                                                <th>Sender</th>
                                                <th>Receiver</th>
                                                <th>Action Code</th>
                                                <th>Action Desc.</th>
                                                <th style="text-align:left">Amount</th>
                                                <th>Date/Time</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="filteredEntries.length > 0">
                                            <tr v-for="(transaction, i) in filteredEntries" :key="i">
                                                <td>{{ transaction.reference_number }}</td>
                                                <td>{{ transaction.acquirer_bank }}</td>
                                                <td>{{ transaction.issuing_bank }}</td>
                                                <td>{{ transaction.transaction_type }}</td>
                                                <td>{{ transaction.source_account_number }}</td>
                                                <td>{{ transaction.destination_account_number }}</td>
                                                <td>{{ transaction.action_code }}</td>
                                                <td>{{ transaction.action_desc }}</td>
                                                <td class="text-right">{{ transaction.transaction_amount }}</td>
                                                <td>{{ transaction.date_create }}</td>

                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="10" class="text-center">No records</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 10px 20px">
                                            <span class="pagination">
                                                showing {{ showInfo.from }} to {{ showInfo.to }} of {{ showInfo.of }} entries
                                            </span>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <ul class="pagination pull-right">
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>                      
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="currentPage < 1 ? currentPage = 1 : currentPage -= 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li v-for="pagi in showPagination" :key="pagi" :class="['page-item', {'disabled': pagi === '...', 'active': pagi === currentPage}]">
                                                    <a href="#" class="page-link" @click.prevent="paginateEvent(pagi)">{{ pagi }}</a>
                                                </li>                    
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a class="page-link" href="#" aria-label="Next" @click.prevent="currentPage > allPages ? currentPage = allPages : currentPage += 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = allPages, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>            
                                                    </a>
                                                </li>                    
                                            </ul>            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>
    </div>
</template>
<style scoped>

</style>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'


import axios from 'axios';
import TopNav from "@/components/TopNav.vue";
import LeftNav from "@/components/LeftNav.vue";
import RightNav from "@/components/RightNav.vue";

import VueSelect from 'vue-next-select'
import '../../../public/css/vue-next-select-refactored.css'

import { $array } from 'alga-js';
import moment from "moment";


import SockJS from "sockjs-client";
import Stomp from "webstomp-client";


export default {
    name: 'SuperRealTimeTxns',
    components:{
        TopNav,
        LeftNav,
        RightNav,
        VueSelect
    },
    data() {
       
        return {
            v$: useVuelidate(),            
            connection: null,

            isConnected: false,

            transTypes: [],
            transType:'',

            allBanks: [],
            bankId: '000001',
            fiName: '',   
            secBankId: '',         
            secBankName: '',            
            transactions: [],
            isDownloading: false,
            firstSearch: false,
            lastUpdateTime: '',
            generalResponse: {},
            netId:0,

            entries:[],
            showEntries:[5, 10, 15, 25, 50, 75, 100],
            currentEntries: 10,
            filteredEntries: [],
            currentPage: 1,
            allPages: 1,
            searchInput: '',
            searchEntries: [],

        }
    },
    validations() {
      return{
        bankId: {
          required,
        },
        transType: {
          required,
        },
             
      }
    },
    methods: {
        handleSearchInput(event){
            console.log(event.fiCode)
            this.bankId = event.fiCode
        },

        async downloadNetReport(){
            this.isDownloading = true;
            let id = this.netId;


            axios({
                url: `report/download_net_report/${id}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
            if (response.status == 200) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.bankId+'_net_settlement_'+moment(new Date()).format("YYYYMMDD_HHmmss")+'.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();
            }else{
                this.$swal('Failed', 'Error downloading report!', 'error')                
            }
            }).catch((err)=>{
                this.$swal('Failed', 'Error downloading report!', 'error')                

            }).finally(()=>{
            this.isDownloading = false;

            });
        },

        async getAllBanks(){
          const response = await axios.get("bank/get_banks");
          if(response.status == 200){
            this.allBanks = response.data;
          }
        },

        async generateNetReport() {
            this.v$.$validate();      
            this.v$.bankId.$touch;
            this.v$.periodId.$touch;

            if (!this.v$.bankId.$invalid && !this.v$.periodId.$invalid) {             
                try{
                    $("#txn_table_div").LoadingOverlay("show");
                    this.isDownloading = true;

                const response = await axios.get(`/report/generate_net_settlement_summary_report/${localStorage.getItem('fullname')}/${this.bankId}/${this.periodId}/${localStorage.getItem('bankcode')}`);
                if (response.status == 200) {
                    this.generalResponse = response.data;
                    if(this.generalResponse.errorCode == "0"){
                        this.$swal('Success', this.generalResponse.errorMessage, 'success')                

                    }else{
                        this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                    }
                }else{
                    this.$swal('Failed', 'Error sending request!', 'error')                

                }
                }catch(err){
                    this.$swal('Failed', 'An unexpected error occurred!', 'error')                

                }finally{
                    $("#txn_table_div").LoadingOverlay("hide");
                    this.isDownloading = false;
                }
            }
        },

        async getNetSettlementReports(value) {
            try{
                $("#txn_table_div").LoadingOverlay("show");
                this.isDownloading = true;

            const response = await axios.get(`report/get_summary_net_settlements`);
            if (response.status == 200) {
                this.transactions = response.data;
                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                this.allPages = $array.pages(this.transactions, this.currentEntries)   

            }else{
                this.$swal('Failed', 'Error loading records!', 'error')                

            }
            }catch(err){
                this.$swal('Failed', 'Error loading records!', 'error')                

            }finally{
                $("#txn_table_div").LoadingOverlay("hide");
                this.isDownloading = false;
            }
        },

        paginateEntries(){
            if(this.searchInput.length >= 1){
                this.searchEntries = $array.search(this.searchInput)(this.transactions)
                this.filteredEntries = $array.paginate(this.searchEntries)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.searchEntries, this.currentEntries)

            }else{
                this.searchEntries = []
                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.transactions, this.currentEntries)
            
            }
        },

        paginateEvent(page){
            this.currentPage = page;
            this.paginateEntries();
        },
    
        searchEvent(){
            this.currentPage = 1
            this.paginateEntries()
        },   
        
        connect() {

            if(this.bankId == '000001'){
                this.v$.$validate();      
                this.v$.bankId.$touch;

                if (!this.v$.bankId.$invalid) {
                    let socket = new SockJS("/transview");

                    this.stompClient = Stomp.over(socket);
                    this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
                    this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
                    this.stompClient.reconnect_delay = 5000;     // client reconnect time in case of network issue
                    this.stompClient.connect({},
                        frame => {
                        this.connected = true;
                        this.isConnected = true;
                        // console.log(frame);
                            this.stompClient.subscribe('/realtime/'+this.bankId, result => {
                                this.transactions = JSON.parse(result.body);

                                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                                this.allPages = $array.pages(this.transactions, this.currentEntries);               
                            });
                        },
                        error => {
                            // console.log(error);
                            this.connected = false;
                            this.isConnected = false;
                        }
                    );
                }

            }else{

                this.v$.$validate();      
                this.v$.bankId.$touch;
                this.v$.transType.$touch;

                if (!this.v$.bankId.$invalid && !this.v$.transType.$invalid) {
                    let socket = new SockJS("/transview");

                    this.stompClient = Stomp.over(socket);
                    this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
                    this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
                    this.stompClient.reconnect_delay = 5000;     // client reconnect time in case of network issue
                    this.stompClient.connect({},
                        frame => {
                        this.connected = true;
                        this.isConnected = true;
                        // console.log(frame);
                            this.stompClient.subscribe('/realtime/'+this.transType+'/'+this.bankId, result => {
                                this.transactions = JSON.parse(result.body);

                                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                                this.allPages = $array.pages(this.transactions, this.currentEntries);               
                            });
                        },
                        error => {
                            // console.log(error);
                            this.connected = false;
                            this.isConnected = false;
                        }
                    );
                }
            }

        },
    
        disconnect() {
            if (this.stompClient) {
                this.stompClient.disconnect();
            }
            this.connected = false;
            this.isConnected = false;
        },        

        reconnect() {
            this.disconnect();

            if(this.bankId == '000001'){
                this.v$.$validate();      
                this.v$.bankId.$touch;

                if (!this.v$.bankId.$invalid) {
                    let socket = new SockJS("/transview");

                    this.stompClient = Stomp.over(socket);
                    this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
                    this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
                    this.stompClient.reconnect_delay = 5000;     // client reconnect time in case of network issue
                    this.stompClient.connect({},
                        frame => {
                        this.connected = true;
                        this.isConnected = true;
                        // console.log(frame);
                            this.stompClient.subscribe('/realtime/'+this.bankId, result => {
                                this.transactions = JSON.parse(result.body);

                                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                                this.allPages = $array.pages(this.transactions, this.currentEntries);               
                            });
                        },
                        error => {
                            // console.log(error);
                            this.connected = false;
                            this.isConnected = false;
                        }
                    );
                }

            }else{

                this.v$.$validate();      
                this.v$.bankId.$touch;
                this.v$.transType.$touch;

                if (!this.v$.bankId.$invalid && !this.v$.transType.$invalid) {
                    let socket = new SockJS("/transview");

                    this.stompClient = Stomp.over(socket);
                    this.stompClient.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
                    this.stompClient.heartbeat.incoming = 0;     // client does not want to receive heartbeats from the server
                    this.stompClient.reconnect_delay = 5000;     // client reconnect time in case of network issue
                    this.stompClient.connect({},
                        frame => {
                        this.connected = true;
                        this.isConnected = true;
                        // console.log(frame);
                            this.stompClient.subscribe('/realtime/'+this.transType+'/'+this.bankId, result => {
                                this.transactions = JSON.parse(result.body);

                                this.filteredEntries = $array.paginate(this.transactions)(this.currentPage, this.currentEntries) 
                                this.allPages = $array.pages(this.transactions, this.currentEntries);               
                            });
                        },
                        error => {
                            // console.log(error);
                            this.connected = false;
                            this.isConnected = false;
                        }
                    );
                }
            }

        },

    },

    mounted() {
        if(localStorage.getItem('bankcode') == '000001'){
            if(localStorage.getItem('rolecode') != '1' && localStorage.getItem('rolecode') != '2' 
                && localStorage.getItem('rolecode') != '3' && localStorage.getItem('rolecode') != '5'
                 && localStorage.getItem('rolecode') != '100'){
                    this.$router.push('/')

                }else{
                    let externalScript = document.createElement('script')
                    externalScript.setAttribute('src', '/js/admin.js')
                    document.body.appendChild(externalScript)
                    this.moment = moment;

                    this.transTypes = [
                        {
                            id: 'all',
                            name: 'ALL'
                        },
                        {
                            id: 'outward',
                            name: 'OUTWARD'
                        },
                        {
                            id: 'inward',
                            name: 'INWARD'
                        },

                    ]
                    this.getAllBanks();
                    // this.getNetSettlementReports();
                    // this.connect();                    
                }
        }else{
            this.$router.push('/')

        }

    },

    computed: {
        showSelectedDetails(){
            // return this.secBankId+' ( '+this.startDate+' - '+this.endDate+' )'
            return this.secBankId + ' - ' + this.secBankName
        },

        showInfo() {
            const getCurrentEntries = this.searchEntries.length <= 0 ? this.transactions : this.searchEntries
            return $array.show(getCurrentEntries)(this.currentPage, this.currentEntries)
        },
        
        showPagination(){
            return $array.pagination(this.allPages, this.currentPage, 1)
        },

    },   
    
    beforeUnmount () {
        this.disconnect()

    },    


}
</script>