<template>
    <div class="theme-ghipss">
        <div class="overlay"></div>
            <top-nav />
        <section>
            <left-nav />
            <right-nav />
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="block-header">
                    <h2>USERS</h2>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-t-15" id="txn_table_div">
                        <div class="card">
                            <div class="row header">
                                <div class="pull-left">
                                    <h2 v-show="firstSearch">
                                        <span> {{ bankId }}</span>
                                    </h2>
                                </div>
                                <div class="pull-right" style="margin:-10px 0px 0px 0px" v-show="firstSearch">
                                    <button type="button" class="btn btn-ghipss btn-circle waves-effect waves-circle waves-float" data-toggle="modal"
                                    data-target="#defaultModal" data-backdrop="static" @click="edit = 0; clearValidation()">
                                        <i class="material-icons">add</i>
                                    </button>                  
                                </div>
                            </div>
                            <div class="row body">
                                <div class="container-fluid">
                                    <div class="pull-left">
                                        <p>
                                            <!-- Excel -->
                                        </p>
                                    </div>
                                    <div class="pull-right">
                                        <div class="form-group" style="margin:-10px 0px 0px 0px">
                                            <div class="form-line">
                                                <input type="text" placeholder="Search here..." v-model="searchInput" class="form-control" @input="searchEvent" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Institution</th>
                                                <th>Status</th>
                                                <th>Created At</th>
                                                <th class="disabled-sorting text-right">Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody v-if="filteredEntries.length > 0">
                                            <tr v-for="(user, i) in filteredEntries" :key="i">
                                                <td>{{ user.fullName }}</td>
                                                <td>{{ user.emailAddress }}</td>
                                                <td>{{ user.roleName }}</td>
                                                <td>{{ user.bankName }}</td>
                                                <td>
                                                    <span v-if="user.active" class="label bg-green">Active</span>
                                                    <span v-else class="label bg-red">Inactive</span>
                                                </td>
                                                <td>{{ getFormattedDate(user.createdAt) }}</td>
                                                <td class="text-right">
                                                    <span v-if="user.active">
                                                        <a href="#" @click="userData = user; roleId = userData.role; edit = 1;" class="btn btn-warning btn-circle waves-effect waves-circle waves-float"
                                                        data-toggle="modal" data-target="#defaultModal" data-backdrop="static">
                                                            <i class="material-icons">edit</i>
                                                        </a>

                                                        <a href="#" @click="userData = user; roleId = userData.role; edit = 2;" class="btn btn-info btn-circle waves-effect waves-circle waves-float m-l-5"
                                                        data-toggle="modal" data-target="#defaultModal" data-backdrop="static">
                                                            <i class="material-icons">vpn_key</i>
                                                        </a>                                                        

                                                        <a @click="userData = user; deleteUser()" class="btn btn-danger btn-circle waves-effect waves-circle waves-float m-l-5">
                                                            <i class="material-icons">delete</i>
                                                        </a>
                                                    </span>
                                                    <span v-else>
                                                        <a @click="userData = user; restoreUser()" class="btn btn-info btn-circle waves-effect waves-circle waves-float m-l-5">
                                                            <i class="material-icons">restore</i>
                                                        </a>
                                                    </span>
                                                </td>

                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="7" class="text-center">No records</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 10px 20px">
                                            <span class="pagination">
                                                showing {{ showInfo.from }} to {{ showInfo.to }} of {{ showInfo.of }} entries
                                            </span>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <ul class="pagination pull-right">
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>                      
                                                <li :class="['page-item', {'disabled': currentPage === 1}]">
                                                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="currentPage < 1 ? currentPage = 1 : currentPage -= 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li v-for="pagi in showPagination" :key="pagi" :class="['page-item', {'disabled': pagi === '...', 'active': pagi === currentPage}]">
                                                    <a href="#" class="page-link" @click.prevent="paginateEvent(pagi)">{{ pagi }}</a>
                                                </li>                    
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a class="page-link" href="#" aria-label="Next" @click.prevent="currentPage > allPages ? currentPage = allPages : currentPage += 1, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                                    </a>
                                                </li>
                                                <li :class="['page-item', {'disabled': currentPage === allPages}]">
                                                    <a href="#" class="page-link" @click.prevent="currentPage = allPages, paginateEntries()">
                                                        <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>            
                                                    </a>
                                                </li>                    
                                            </ul>            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>

        <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title" v-if="edit == 1">
                            Edit User ( {{ userData.fullName }} )
                        </h2>
                        <h2 class="modal-title" v-if="edit == 2">
                            Change Password ( {{ userData.fullName }} )
                        </h2>                        
                        <h2 v-if="edit == 0" class="modal-title" id="defaultModalLabel">Add User</h2>
                    </div>
                    <div class="modal-body">
                            <form>
                                <span v-if="edit==0 || edit==1">
                                    <label for="email_address">Full Name</label>
                                    <div class="form-group">
                                        <div class="form-line">
                                            <input type="text" v-model="userData.fullName" class="form-control" placeholder="Enter Full Name">
                                        </div>
                                        <span v-if="v$.userData.fullName.$error" class="text-danger">
                                            {{ v$.userData.fullName.$errors[0].$message }}
                                        </span>                                         
                                    </div>

                                    <label for="password">Email Address</label>
                                    <div class="form-group">
                                        <div class="form-line">
                                            <input type="text" v-model="userData.emailAddress" class="form-control" placeholder="Enter Email Address">
                                        </div>
                                        <span v-if="v$.userData.emailAddress.$error" class="text-danger">
                                            {{ v$.userData.emailAddress.$errors[0].$message }}
                                        </span>                                         
                                    </div>

                                    <label for="password">Institution</label>
                                    <div class="form-group">   
                                        <vue-select
                                            v-model="userData.bank"
                                            label-by="bankName"
                                            value-by="fiCode"
                                            searchable
                                            close-on-select
                                            :options="allBanks"
                                            @selected="handleInput"
                                            placeholder="select an institution"
                                        />
                                        <span v-if="v$.userData.bank.$error" class="text-danger">
                                            {{ v$.userData.bank.$errors[0].$message }}
                                        </span>                                         
                                    </div>

                                    <label for="password">Role</label>
                                    <div class="demo-radio-button">
                                        <span v-for="role in allRoles" :key="role.id">
                                            <input :id="role.id" name="roles" v-model="roleId" :value="role.code" type="radio" :checked="role.code == roleId" class="radio-col-black" @change="onChange($event)"  />
                                            <label :for="role.id">{{ role.name }}</label>
                                        </span>                                         
                                    </div>
                                    <span v-if="v$.roleId.$error" class="text-danger">
                                        {{ v$.roleId.$errors[0].$message }}
                                    </span>
                                </span>                        

                                <span v-if="edit==0 || edit == 2">
                                    <div class="input-group m-t-5">
                                        <label for="password">New Password</label>
                                        <div class="form-line">
                                            <input :type="passwordVisibitity.passwordFieldType" class="form-control" v-model="newPassword" placeholder="Enter new password" autofocus>
                                        </div>
                                        <span v-if="v$.newPassword.$error" class="text-danger">
                                            {{ v$.newPassword.$errors[0].$message }}
                                        </span>                                        
                                        <span class="input-group-addon" @click="switchVisibility">
                                            <i :class="passwordVisibitity.passwordFieldIcon"></i>
                                        </span>
                                    </div>

                                    <div class="input-group">
                                        <label for="password">Repeat Password</label>                            
                                        <div class="form-line">
                                            <input :type="passwordVisibitity1.passwordFieldType" class="form-control" v-model="repeatPassword" placeholder="Re-enter new Password">
                                        </div>
                                        <span v-if="v$.repeatPassword.$error" class="text-danger">
                                            {{ v$.repeatPassword.$errors[0].$message }}
                                        </span>                                        
                                        <span class="input-group-addon" @click="switchVisibility1">
                                            <i :class="passwordVisibitity1.passwordFieldIcon"></i>
                                        </span>                            
                                    </div>
                                </span>
                            </form>
                    </div>
                    <div class="modal-footer" v-if="edit == 0">                        
                        <button type="button" class="btn btn-success waves-effect" @click.prevent="submitAddUserForm">Save</button>
                        <button type="button" class="btn btn-danger waves-effect" @click="clearValidation;closeModal()">Close</button>

                    </div>
                    <div class="modal-footer" v-else-if="edit == 1">
                        <button type="button" class="btn btn-warning waves-effect" @click.prevent="submitUpdateUserForm">Update</button>
                        <button type="button" class="btn btn-danger waves-effect" @click="clearValidation;closeModal()">Close</button>
                    </div>
                    <div class="modal-footer" v-else-if="edit == 2">
                        <button type="button" class="btn btn-default waves-effect" @click.prevent="submitUpdatePasswordForm">Change</button>
                        <button type="button" class="btn btn-danger waves-effect" @click="clearValidation;closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>    
</template>
<style scoped>

</style>
<script>
import useVuelidate from '@vuelidate/core'
import { required,email,sameAs } from '@vuelidate/validators'


import axios from 'axios';
import TopNav from "@/components/TopNav.vue";
import LeftNav from "@/components/LeftNav.vue";
import RightNav from "@/components/RightNav.vue";

import VueSelect from 'vue-next-select'
import '../../../public/css/vue-next-select-refactored.css'


import { $array } from 'alga-js';
import moment from "moment";

export default {
    name: 'SuperUsers',
    components: {
        TopNav,
        LeftNav,
        RightNav,
        VueSelect

    },
    data() {
        return {
            v$: useVuelidate(),

            edit: 0,
            allUsers: [],
            allBanks: [],
            allRoles: [],
            roleId: '',
            bankId: '',
            // transactions: [],
            isDownloading: false,
            firstSearch: false,
            lastUpdateTime: '',
            newPassword: '',
            repeatPassword: '',

            generalResponse: {},
            passwordVisibitity: {
                passwordFieldType: "password",
                passwordFieldIcon: "fa fa-eye"
                
            },
            passwordVisibitity1: {
                passwordFieldType: "password",
                passwordFieldIcon: "fa fa-eye"
                
            },

            userData: {
                id: 0,
                fullName: '',
                emailAddress: '',
                role: '',
                roleName: '',
                bank: '',
                bankName: '',
                status: '',
                active: false,
                createdBy: '',
                createdAt: '',
                updatedAt: '',
                
            },

            entries:[],
            showEntries:[5, 10, 15, 25, 50, 75, 100],
            currentEntries: 10,
            filteredEntries: [],
            currentPage: 1,
            allPages: 1,
            searchInput: '',
            searchEntries: [], 

        }
    },
    validations() {
      return{
        userData:{
            fullName: {
                required,
            },
            emailAddress: {
                required,
                email,
            },
            bank: {
                required,
            },
        },
        roleId:{
            required,
        },  
        newPassword: {
          required,
        },
        repeatPassword: {
          required,
          sameAs: sameAs(this.newPassword)
        },                                                                   
      }
    },

    methods: {
        closeModal() {
            $('#defaultModal').modal('hide');
        },
        

        onChange(event) {
            //   var data = event.target.value;
            //   console.log(event);
        },

        handleInput(event){
            // console.log(event)
        },

        switchVisibility() {
            this.passwordVisibitity.passwordFieldType = this.passwordVisibitity.passwordFieldType === "password" ? "text" : "password";
            this.passwordVisibitity.passwordFieldIcon = this.passwordVisibitity.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
        },

        switchVisibility1() {
            this.passwordVisibitity1.passwordFieldType = this.passwordVisibitity1.passwordFieldType === "password" ? "text" : "password";
            this.passwordVisibitity1.passwordFieldIcon = this.passwordVisibitity1.passwordFieldType === "password" ? "fa fa-eye" : "fa fa-eye-slash"
        },        

        async getAllBanks(){
          const response = await axios.get("bank/get_banks");
          if(response.status == 200){
            this.allBanks = response.data;
          }
        },  
        
        async getAllRoles(){
          const response = await axios.get("role/get_roles");
          if(response.status == 200){
            this.allRoles = response.data;
          }
        },          

        async submitAddUserForm(){
            this.v$.$validate() 
            this.v$.userData.fullName.$touch;
            this.v$.userData.emailAddress.$touch;
            this.v$.userData.bank.$touch;
            this.v$.roleId.$touch;
            this.v$.newPassword.$touch;
            this.v$.repeatPassword.$touch;

            if (!this.v$.userData.fullName.$invalid && !this.v$.userData.emailAddress.$invalid && !this.v$.userData.bank.$invalid 
                && !this.v$.roleId.$invalid && !this.v$.newPassword.$invalid && !this.v$.repeatPassword.$invalid) { 
                $.LoadingOverlay("show");
                try {
                    const data = {
                        bank: this.userData.bank,
                        createdBy: localStorage.getItem('userid'),
                        emailAddress: this.userData.emailAddress,
                        fullName: this.userData.fullName,
                        password: this.newPassword,
                        role: this.roleId,
                    }

                    const response = await axios.post('user/create_user', data);
                    if(response.status == 200){
                        this.generalResponse = response.data;
                        if(this.generalResponse.errorCode == '0'){
                        this.$swal('Success', 'User added successfully!', 'success')                

                        this.getAllUsers();

                        $('#defaultModal').modal('hide');

                        }else{
                        this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                        }
                    }else{
                        this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                    }
                }
                catch(err) {
                    this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                }finally{
                    $.LoadingOverlay("hide");
                    this.clearValidation();
                }

            }

        },

        async submitUpdateUserForm(){
            this.v$.$validate() 
            this.v$.userData.fullName.$touch;
            this.v$.userData.emailAddress.$touch;
            this.v$.userData.bank.$touch;
            this.v$.roleId.$touch;

            if (!this.v$.userData.fullName.$invalid && !this.v$.userData.emailAddress.$invalid && !this.v$.userData.bank.$invalid 
                && !this.v$.roleId.$invalid) { 
                $.LoadingOverlay("show");

                try {
                    const data = {
                        id: this.userData.id,
                        bank: this.userData.bank,
                        createdBy: localStorage.getItem('userid'),
                        emailAddress: this.userData.emailAddress,
                        fullName: this.userData.fullName,
                        role: this.roleId,
                    }
                    const response = await axios.post('user/update_user_details', data);
                    if(response.status == 200){
                        this.generalResponse = response.data;
                        if(this.generalResponse.errorCode == '0'){
                        this.$swal('Success', 'User updated successfully!', 'success')                

                        this.getAllUsers();

                        }else{
                        this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                        }
                    }else{
                        this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                    }

                }
                catch(err) {
                    this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                }finally{
                    $.LoadingOverlay("hide");
                    this.clearValidation();
                    $('#defaultModal').modal('hide');
                    
                }

            }

        },

        async submitUpdatePasswordForm(){
            this.v$.$validate();      
            this.v$.newPassword.$touch;
            this.v$.repeatPassword.$touch;

            if (!this.v$.newPassword.$invalid && !this.v$.repeatPassword.$invalid) { 
                $.LoadingOverlay("show");

                try {
                    const data = {
                        username: this.userData.emailAddress,
                        oldPassword: this.newPassword,
                        newPassword: this.newPassword,
                    }
                    const response = await axios.post(`user/change_user_password/${localStorage.getItem('userid')}`, data);
                    if(response.status == 200){
                        this.generalResponse = response.data;
                        if(this.generalResponse.errorCode == '0'){
                            this.$swal('Success', 'Password changed successfully!', 'success')                

                        }else{
                            this.$swal('Failed', this.generalResponse.errorMessage, 'error')                

                        }
                    }else{
                        this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                    }

                }
                catch(err) {
                    this.$swal('Failed', 'An unexpected error occurred...Try again!', 'error')                

                }finally{
                    $.LoadingOverlay("hide");
                    this.clearValidation();
                    $('#defaultModal').modal('hide');
                    
                }

            }

        },

        async deleteUser() {
            this.$swal({
            title: 'Deactivate ( '+ this.userData.fullName +' )',
            text: 'Are you sure you want to deactivate this user?',
            // type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No!',
            showCloseButton: true,
            showLoaderOnConfirm: true
            }).then((result) => {
            if(result.value) {
                axios.delete('user/deactivate_user/'+this.userData.id)
                    .then(res => {
                        if(res.data.errorCode == '0'){
                            this.$swal('Success', 'User successfully deactivated!', 'success')
                            this.getAllUsers();

                        }else{
                            this.$swal('Failed', res.data.errorMessage, 'error')
                        }
                    }).catch(err => {
                        this.$swal('Failed', 'User could not be deactivated. Try again!', 'error')
                    });
            } 

            })
        },

        async restoreUser() {
            this.$swal({
            title: 'Reactivate ( '+ this.userData.fullName +' )',
            text: 'Are you sure you want to reactivate this user?',
            // type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No!',
            showCloseButton: true,
            showLoaderOnConfirm: true
            }).then((result) => {
            if(result.value) {
                axios.post('user/reactivate_user/'+this.userData.id)
                    .then(res => {
                        if(res.data.errorCode == '0'){
                            this.$swal('Success', 'User successfully reactivated!', 'success')
                            this.getAllUsers();

                        }else{
                            this.$swal('Failed', res.data.errorMessage, 'error')
                        }
                    }).catch(err => {
                        this.$swal('Failed', 'User could not be reactivated. Try again!', 'error')
                    });
            } 

            })
        },

        clearValidation(){
            this.v$.$reset();            
            this.userData= {
                id: 0,
                fullName: '',
                emailAddress: '',
                role: null,
                roleName: '',
                bank: null,
                bankName: '',
                status: '',
                active: false,
                createdBy: '',
                createdAt: '',
                updatedAt: '',

            }
            this.passwordVisibitity= {
                passwordFieldType: "password",
                passwordFieldIcon: "fa fa-eye"
                
            }
            this.passwordVisibitity1= {
                passwordFieldType: "password",
                passwordFieldIcon: "fa fa-eye"
                
            }
            this.newPassword = ''
            this.repeatPassword = ''
            this.edit = 0
           this.roleId = null

        },

        async getAllUsers(){
            try{
                $("#txn_table_div").LoadingOverlay("show");
                this.isDownloading = true;

            const response = await axios.get("user/get_users");
            if (response.status == 200) {
                this.allUsers = response.data;
                this.filteredEntries = $array.paginate(this.allUsers)(this.currentPage, this.currentEntries) 
                this.allPages = $array.pages(this.allUsers, this.currentEntries)   

                // this.totalAmount = response.data.map(e => parseFloat(e.transaction_amount)).reduce((a,b) => a + b, 0);
                this.lastUpdateTime = moment(new Date()).format('hh:mm a');

                this.firstSearch = true;
                this.lastUpdateTime = moment(new Date()).format('hh:mm a');

            }else{
                this.$swal('Failed', 'Error loading records!', 'error')                

            }
            }catch(err){
                this.$swal('Failed', 'Error loading records!', 'error')                

            }finally{
                $("#txn_table_div").LoadingOverlay("hide");
                this.isDownloading = false;
            }
        },

        paginateEntries(){
            if(this.searchInput.length >= 1){
                this.searchEntries = $array.search(this.searchInput)(this.allUsers)
                this.filteredEntries = $array.paginate(this.searchEntries)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.searchEntries, this.currentEntries)

            }else{
                this.searchEntries = []
                this.filteredEntries = $array.paginate(this.allUsers)(this.currentPage, this.currentEntries)
                this.allPages = $array.pages(this.allUsers, this.currentEntries)
            
            }
        },

        paginateEvent(page){
            this.currentPage = page;
            this.paginateEntries();
        },
    
        searchEvent(){
            this.currentPage = 1
            this.paginateEntries()
        }, 

        getFormattedDate(date) {
            return moment(date).format("YYYY-MM-DD  hh:mm a");
        }, 

    },
    mounted() {
        if(localStorage.getItem('bankcode') == '000001'){
            if(localStorage.getItem('rolecode') != '4' && localStorage.getItem('rolecode') != '100'){
                this.$router.push('/')
            }else{          
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', '/js/admin.js')
                document.body.appendChild(externalScript)
                this.moment = moment;

                this.getAllUsers();
                this.getAllBanks();
                this.getAllRoles();
            }
        }else{
            this.$router.push('/')

        }
    },
    computed: {
        showInfo() {
            const getCurrentEntries = this.searchEntries.length <= 0 ? this.allUsers : this.searchEntries
            return $array.show(getCurrentEntries)(this.currentPage, this.currentEntries)
        },
        
        showPagination(){
            return $array.pagination(this.allPages, this.currentPage, 1)
        }
    },        
}
</script>